@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

div,
p,
ul,
li,
span,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: 'Montserrat';
}

body {
  font-family: 'Montserrat';
  font-size:10px;
}

.img-footer {
  cursor: pointer;
}
.img-footer:hover {
  opacity:0.6
}

/* 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error-connection {
  position: fixed;
  bottom:20px;
  left:20px;
  padding:0.5rem 2rem;
  border-radius:1rem;
  background-color: rgba(255,0,0, 0.66);
  color:white;
  border:solid 1px red;
  z-index:1000;
}

.container {
  width: 100vw;
  height: 100vh;
  background-color: white;
  overflow: hidden;
  position: relative;
}


img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 50vw;
}

.form {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 2rem 4rem;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  max-width:500px;
  overflow: auto;

  border-left: solid 1px lightgray;
}

h1 {
  font-size: 3rem;
}

p,
span {
  font-size: 1.2rem;
}

input,
select {
  border: solid 1px black;
  font-family: "Lobster", cursive;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

input.error {
  border:solid 3px red!important;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexCol input {
  margin-bottom: 0.5rem;
}

.button_container {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.button {
  cursor: pointer;

  padding: 1rem 2rem;
  border-radius: 1rem;
  background-color: black;
  color: white;
  text-align: center;

  font-family: "Lobster", cursive;
  font-size: 2rem;
}

.btn-small {
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
}

.info-container > p {
margin:0.2rem 0;
}

.list-item {
  display:flex;
  align-items:center;
}

.btn-remove {
  margin-left:0.5rem;
  background-color:black;
  color:white;
  border-radius:50%;
  cursor:pointer;
  width:24px;
  height:24px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.button:hover {
  opacity: 0.5;
}

.success-snack {
  padding:0.5rem 2rem;
  border-radius:1rem;
  background-color: rgba(50,200,125, 0.8);
  color:white;
  border:solid 1px green;
}
.error-snack {
  padding:0.5rem 2rem;
  border-radius:1rem;
  background-color: rgba(255,0,0, 0.66);
  color:white;
  border:solid 1px red;
} */
